// extracted by mini-css-extract-plugin
export var base = "MarketingPaidSearchPage-module--base--2YGDQ";
export var rightAlignedContainer = "MarketingPaidSearchPage-module--rightAlignedContainer--ELmZ1";
export var rightAlignedContainerSmall = "MarketingPaidSearchPage-module--rightAlignedContainerSmall--29Gss";
export var leftAlignedContainer = "MarketingPaidSearchPage-module--leftAlignedContainer--197kY";
export var blogContainer = "MarketingPaidSearchPage-module--blogContainer--2jFNW MarketingPaidSearchPage-module--base--2YGDQ";
export var container = "MarketingPaidSearchPage-module--container--PX5L_ MarketingPaidSearchPage-module--base--2YGDQ";
export var largeContainer = "MarketingPaidSearchPage-module--largeContainer--2nIgo MarketingPaidSearchPage-module--base--2YGDQ";
export var mobileSmallPadding = "MarketingPaidSearchPage-module--mobileSmallPadding--3ApIP";
export var fullWidthMobile = "MarketingPaidSearchPage-module--fullWidthMobile--2om8G";
export var gridWithSidebar = "MarketingPaidSearchPage-module--gridWithSidebar--2tWtm";
export var noMaxWidth = "MarketingPaidSearchPage-module--noMaxWidth--2gTB_";
export var copyLockupList = "MarketingPaidSearchPage-module--copyLockupList--2Aun-";
export var featuredTemplates = "MarketingPaidSearchPage-module--featuredTemplates--2eUIl";
export var hero = "MarketingPaidSearchPage-module--hero--3OgMr";
export var categorizedFeaturedTemplates = "MarketingPaidSearchPage-module--categorizedFeaturedTemplates--3n8R2";