import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CategorizedFeaturedTemplatesModule, { CategorizedFeaturedTemplatesDataPropsObject } from '../../components/CategorizedFeaturedTemplatesModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import FeaturedTemplatesModule, { FeaturedTemplatesDataPropsObject } from '../../components/FeaturedTemplatesModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import {
  categorizedFeaturedTemplates as categorizedFeaturedTemplatesClass,
  copyLockupList as copyLockupListClass,
  featuredTemplates as featuredTemplatesClass,
  hero as heroClass
} from './MarketingPaidSearchPage.module.css';

export default function MarketingPaidSearchPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;
  const SEOInfo = {
    title         : page.title,
    description   : description && description.description,
    slug          : page.slug,
    canonicalSlug : canonicalUrlPage && canonicalUrlPage.slug,
    noIndex       : page.noIndex
  };

  const {
    categorizedFeaturedTemplates,
    copyLockupList,
    featuredTemplates,
    hero,
    pitch
  } = componentsQueryResultToData(components);

  if (imageFieldName && imageFieldName.file && imageFieldName.file.url) {
    SEOInfo.image = imageFieldName.file.url;
  }

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={heroClass} data={hero} />
      {categorizedFeaturedTemplates
      && (<CategorizedFeaturedTemplatesModule className={`${categorizedFeaturedTemplatesClass} marketingPaidSearchPageHideModule o-MarketingPaidSearchPage-CFTModule`} data={categorizedFeaturedTemplates} hasBackgroundColor />)}
      <FeaturedTemplatesModule className={`${featuredTemplatesClass} o-MarketingPaidSearchPage-FTModule`} data={featuredTemplates} />
      <CopyLockupListModule childrenLayout="centered" className={copyLockupListClass} data={copyLockupList} />
      <PitchModule
        data={pitch}
        hideBottomWave
      />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingPaidSearchPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingPaidSearchPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCategorizedFeaturedTemplates']),
          ...CategorizedFeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentFeaturedTemplates']),
          ...FeaturedTemplatesDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
