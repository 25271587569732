import {
  FeaturedTemplates,
  FeaturedTemplatesWrap,
  SlideyTabs,
  Tab,
  TabList,
  TabPanel } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import FeaturedTemplateModule, { FeaturedTemplateDataProps } from '../FeaturedTemplateModule';
import StyledSectionBackground from '../StyledSectionBackground';
import { container, marginContainer } from './CategorizedFeaturedTemplatesModule.module.css';

export default function CategorizedFeaturedTemplatesModule({
  blobs,
  className,
  data,
  hasBackgroundColor,
  withSectionBlobs
}) {
  const {
    body,
    hasBackgroundColor: hasBackgroundColorFromData,
    hasMarginBottom,
    leftCaretTrackingClass,
    rightCaretTrackingClass,
    tagline,
    title,
    featuredTemplateCategoryGroups
  } = data;

  const categories = featuredTemplateCategoryGroups.map(({ categoryTitle }) => categoryTitle);
  // Select middle tab if there's an odd number of tabs
  const hasOddNumberOfCategories = featuredTemplateCategoryGroups.length % 2;
  const initialActiveTabIndex = hasOddNumberOfCategories ? Math.floor(featuredTemplateCategoryGroups.length / 2) : 0;
  const classes = classNames(className, container, {
    [marginContainer] : hasMarginBottom
  });
  /* eslint-disable react/no-array-index-key */
  const showBackgroundColor = hasBackgroundColorFromData || hasBackgroundColor;
  return (
    <StyledSectionBackground className={classes} hasBackgroundColor={showBackgroundColor} withBlobs={withSectionBlobs}>
      {blobs}
      <FeaturedTemplates
        body={body && body.body}
        tagline={tagline}
        title={title}
        withBottomFooterSpacing={false}
      >
        <SlideyTabs
          backgroundColor="light-grey"
          initialActiveTabIndex={initialActiveTabIndex}
          leftCaretTrackingClass={leftCaretTrackingClass}
          rightCaretTrackingClass={rightCaretTrackingClass}
        >
          <TabList>
            {categories.map((category) => <Tab key={category}>{category}</Tab>)}
          </TabList>
          {featuredTemplateCategoryGroups.map((group) => (
            <TabPanel key={group.id}>
              <FeaturedTemplatesWrap
                primaryCtaCopy={group.primaryCtaCopy}
                primaryCtaTrackingClass={group.primaryCtaTrackingClass}
                primaryCtaTrackingClassMobile={group.primaryCtaTrackingClassMobile}
                primaryCtaUrl={group.primaryCtaUrl}
                secondaryDesktopCtaCopy={group.secondaryDesktopCtaCopy}
                secondaryDesktopCtaTrackingClass={group.secondaryDesktopCtaTrackingClass}
                secondaryDesktopCtaUrl={group.secondaryDesktopCtaUrl}
                secondaryMobileCtaCopy={group.secondaryMobileCtaCopy}
                secondaryMobileCtaTrackingClass={group.secondaryMobileCtaTrackingClass}
                secondaryMobileCtaUrl={group.secondaryMobileCtaUrl}
              >
                {group.templates.map((template) => (
                  <FeaturedTemplateModule key={template.id} data={template} />
                ))}
              </FeaturedTemplatesWrap>
            </TabPanel>
          ))}
        </SlideyTabs>
      </FeaturedTemplates>
    </StyledSectionBackground>
  );
  /* eslint-enable react/no-array-index-key */
}

export const CategorizedFeaturedTemplatesDataPropsObject = {
  body : PropTypes.shape({
    body : PropTypes.string
  }),
  featuredTemplateCategoryGroups : PropTypes.arrayOf(
    PropTypes.shape({
      categoryTitle                    : PropTypes.string,
      id                               : PropTypes.string,
      primaryCtaCopy                   : PropTypes.string,
      primaryCtaTrackingClass          : PropTypes.string,
      primaryCtaTrackingClassMobile    : PropTypes.string,
      primaryCtaUrl                    : PropTypes.string,
      secondaryDesktopCtaCopy          : PropTypes.string,
      secondaryDesktopCtaTrackingClass : PropTypes.string,
      secondaryDesktopCtaUrl           : PropTypes.string,
      secondaryMobileCtaCopy           : PropTypes.string,
      secondaryMobileCtaTrackingClass  : PropTypes.string,
      secondaryMobileCtaUrl            : PropTypes.string,
      templates                        : PropTypes.arrayOf(FeaturedTemplateDataProps).isRequired
    })
  ).isRequired,
  hasBackgroundColor      : PropTypes.bool,
  hasMarginBottom         : PropTypes.bool,
  leftCaretTrackingClass  : PropTypes.string,
  rightCaretTrackingClass : PropTypes.string,
  tagline                 : PropTypes.string,
  title                   : PropTypes.string
};

CategorizedFeaturedTemplatesModule.propTypes = {
  blobs              : PropTypes.node,
  className          : PropTypes.string,
  data               : PropTypes.shape(CategorizedFeaturedTemplatesDataPropsObject).isRequired,
  hasBackgroundColor : PropTypes.bool,
  withSectionBlobs   : PropTypes.bool
};

CategorizedFeaturedTemplatesModule.defaultProps = {
  blobs              : null,
  className          : null,
  hasBackgroundColor : false,
  withSectionBlobs   : false
};

export const CategorizedFeaturedTemplatesModuleData = graphql`
  fragment CategorizedFeaturedTemplatesModuleData on ContentfulComponentCategorizedFeaturedTemplates {
    body {
      body
    }
    featuredTemplateCategoryGroups {
      id
      categoryTitle
      primaryCtaCopy
      primaryCtaTrackingClass
      primaryCtaTrackingClassMobile
      primaryCtaUrl
      secondaryDesktopCtaCopy
      secondaryDesktopCtaTrackingClass
      secondaryDesktopCtaUrl
      secondaryMobileCtaCopy
      secondaryMobileCtaTrackingClass
      secondaryMobileCtaUrl
      templates {
        ...FeaturedTemplateModuleData
      }
    }
    hasBackgroundColor
    hasMarginBottom
    leftCaretTrackingClass
    rightCaretTrackingClass
    tagline
    title
  }
`;
