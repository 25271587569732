import { PinkBlob, PinkCorner } from '@animoto/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  backgroundColor,
  container,
  leftCorner,
  mobileWhiteBackground,
  rightCorner,
  wrap
} from './StyledSectionBackground.module.css';

export default function StyledSectionBackground({
  blobs,
  children,
  className,
  hasBackgroundColor,
  hasMobileWhiteBackground,
  withBlobs
}) {
  const wrapStyles = classNames(className, wrap, {
    [mobileWhiteBackground] : hasMobileWhiteBackground,
    [backgroundColor]       : hasBackgroundColor
  });
  return (
    <div className={wrapStyles}>
      {blobs}
      <div className={container}>
        {children}
      </div>
      { withBlobs && (
        <>
          <PinkCorner className={leftCorner} />
          <PinkBlob className={rightCorner} />
        </>
      ) }
    </div>
  );
}

StyledSectionBackground.propTypes = {
  blobs                    : PropTypes.node,
  children                 : PropTypes.node.isRequired,
  className                : PropTypes.string,
  hasBackgroundColor       : PropTypes.bool,
  hasMobileWhiteBackground : PropTypes.bool,
  withBlobs                : PropTypes.bool
};

StyledSectionBackground.defaultProps = {
  blobs                    : null,
  className                : null,
  hasBackgroundColor       : false,
  hasMobileWhiteBackground : false,
  withBlobs                : false
};
